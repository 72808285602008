
export default function invertTimeline(timeline, min, max) {
  timeline = timeline.sort((a, b) => a.from - b.from);
  const inverted = [];
  let from = min;
  for (let i = 0; i < timeline.length; i++) {
    const slot = timeline[i];
    inverted.push({ type: 'free', from: from, to: slot.from });
    from = slot.to;
  }
  inverted.push({ type: 'free', from: from, to: max });
  return inverted;
}