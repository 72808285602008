<template>
  <div>
    <div class="d-flex justify-content-between mb-2">
      <h2>Schedules</h2>
      <div>
      </div>
    </div>
    <hr>
    <b-row>
      <b-col cols="3">
        <b-form-select :options="groupOptions" v-model="groupId">
          <template #first>
            <b-form-select-option :value="null" disabled>Select group</b-form-select-option>
          </template>
        </b-form-select>
        <b-form-select class="mt-2" :options="timezoneOptions" v-model="timezone">
          <template #first>
            <b-form-select-option :value="null" disabled>Select timezone</b-form-select-option>
          </template>
        </b-form-select>
        <v-date-picker
          class="mt-3"
          ref="calendar"
          mode="date"
          :model-config="{
            type: 'string',
            mask: 'YYYY-MM-DD',
          }"
          timezone="UTC"
          show-weeknumbers="right"
          is-range
          @input="handleDateRangeInput"
          :first-day-of-week="2"
          v-model="dateRange" />
      </b-col>
      <b-col cols="9">
        <apexchart
          v-show="dateRange.start !== dateRange.end && groupId !== null"
          type="rangeBar" height="350" :options="chartOptions" :series="series"></apexchart>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import countriesAndTz from 'countries-and-timezones';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import commonTimeline from '@/utils/common-timeline';
dayjs.extend(utc);
dayjs.extend(tz);
export default {
  data() {
    return {
      timezone: 'Asia/Manila',
      dateRange: { from: null, to: null },
      groupId: null
      //fromDateStr: '2021-04-19',
      //toDateStr: '2021-04-26'
    }
  },
  components: {
  },
  computed: {
    groupOptions() {
      const options = this.$store.getters.groupSelectorList;
      return options;
    },
    fromDate() {
      return dayjs.tz(this.dateRange.start, this.timezone).utc(true).toDate();
    },
    toDate() {
      return dayjs.tz(this.dateRange.end, this.timezone).add(1, 'day').utc(true).toDate();
    },
    series() {
      const content = this.$store.getters.scheduleDiagramContent;
      if (!content) {
        return [{
          data: []
        }]
      }
      const data = [];
      const timezone = this.timezone;
      const alignedTimeslots = [];
      content.forEach((row) => {
        const key = `${row.firstName} ${row.lastName}`;

        /*const studentTimezone = row.timezone;
        for (let m = dayjs(this.fromDate).startOf('day'); m.isBefore(this.toDate); m = m.add(1, 'days')) {
          data.push({
            x: key,
            y: [m.add(23, 'hours').unix() * 1000, m.add(30, 'hours').unix() * 1000],
            fillColor: '#e3d110'
          });
        }*/

        const timeslots = [];
        alignedTimeslots.push(timeslots);
        if (row.timeline.length > 0) {
          row.timeline.forEach((slot) => {
            timeslots.push({
              from: dayjs.tz(slot.from, row.timezone).tz(timezone, true).unix()*1000,
              to: dayjs.tz(slot.to, row.timezone).tz(timezone, true).unix()*1000
            });

            data.push({
              x: key,
              y: [
                dayjs.tz(slot.from, row.timezone).tz(timezone, true).unix()*1000,
                dayjs.tz(slot.to, row.timezone).tz(timezone, true).unix()*1000
              ],
              fillColor: '#FF4560'
            })
          });
        } else {
          data.push({
            x: key,
            y: [this.fromDate,this.toDate],
            fillColor: '#FFFFFF22'
          })
        }
      });
      const commonTl = commonTimeline(alignedTimeslots.map((c) => c), this.fromDate.getTime(), this.toDate.getTime());
      for (let i = 0; i < commonTl.length; i += 2) {
        const diff = commonTl[i+1] - commonTl[i];
        data.push({
          x: 'Availability',
          y: [dayjs(commonTl[i]).unix()*1000, dayjs(commonTl[i+1]).unix()*1000],
          fillColor: diff >= (3 * 60 * 60 * 1000) ? '#77dd77' : '#6b6b6b'
        })
      }
      return [{
        data
      }];
    },
    chartOptions() {
      return {
        chart: {
          type: 'rangeBar',
          animations: {
            enabled: false
          }
        },
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: '15%'
          }
        },
        xaxis: {
          type: 'datetime',
          min: this.fromDate.getTime(),
          max: this.toDate.getTime()
        },
        grid: {
          xaxis: {
            lines: {
              show: true
            }
          }
        },
        tooltip: {
          x: {
            format: 'dd MMM HH:mm'
          }
        }
      }
    },
    timezoneOptions() {
      const timezones = countriesAndTz.getAllTimezones();
      if (!timezones) {
        return [];
      }
      return Object.keys(timezones)
        .filter(tz => ['Asia/Manila', 'Asia/Yerevan', 'Asia/Riyadh'].includes(tz))
        .map((tz) => {
        tz = timezones[tz];
        return {
          text: `${tz.name} (${tz.utcOffsetStr})`,
          value: tz.name
        };
      });
    }
  },
  mounted() {
    this.$store.dispatch('fetchGroups');
  },
  methods: {
    handleDateRangeInput() {
      if (this.dateRange.end !== this.dateRange.start) {
        this.refreshDiagram();
      }
    },
    async refreshDiagram() {
      /*const sampleIds = [
        'tdM7EbKvfR6NmYSpoaTB',
        'eeuetYDTTDbzE79ukPYA',
        'aKOZTVlWXJAXwg5NLY2t'
      ];*/
      if (!this.groupId) {
        return;
      }
      const ids = await this.$store.dispatch('fetchStudentIdsOfGroup', this.groupId)
      if (ids.length > 0) {
        const diagram = await this.$store.dispatch('prepareScheduleDiagramContent', {
          studentIds: ids,
          from: this.fromDate,
          to: this.toDate,
          timezone: 'Asia/Manila'
        });
      }
    }
  },
  watch: {
    groupId() {
      this.refreshDiagram();
    }
  }
}
</script>