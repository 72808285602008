import invertTimeline from './invert-timeline';
const timeOverlap = require('time-overlap');

export default function commonTimeline(timelines, minTimestamp, maxTimestamp) {
  timelines = timelines.map((tl) => {
    tl = invertTimeline(tl, minTimestamp, maxTimestamp);
    return tl.map((slot) => {
      return [slot.from, slot.to]
    }).flat();
  });
  if (timelines.length > 0) {
    let intersection = timelines[0];
    for (let i = 1; i < timelines.length; i++) {
      intersection = timeOverlap.cross(intersection, timelines[i]);
    }
    return intersection;
  }
  return [];
}